@import "src/app/global/variables.scss";
.Header {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(143, 126, 200, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 300ms;

    background-color: transparent;
    &.black {
        background-color: $background;
    }

    &.close {
        transform: translateY(-100%);
    }

    &.white {
        p {
            color: black;
        }
        background-color: white;
    }
}

.desktopContainer {
    width: 100%;
    max-width: 1920px;
    padding: 24px 80px;
    background-color: transparent;
    &.black {
        background-color: $background;
    }

    transition-duration: 300ms;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Navigation {
    display: flex;
}

.buttonContainer {
    display: flex;
    gap: 12px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
    }
}

.LogInButton {
    padding: 8px 24px;
    border-radius: 6px;
}

.RegisterButton {
    padding: 8px 16px;
    border-radius: 6px;
}

.mobileContainer {
    position: relative;
    z-index: 9999;
    width: 100%;
    padding: 32px;

    background-color: transparent;
    &.black {
        background-color: $background;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuIcon {
    path {
        stroke: white !important;
    }

    &.black {
        path {
            stroke: black !important;
        }
    }

    &.open {
        path {
            stroke: white !important;
        }
    }
}

.MiniLogo {
    g {
        path {
            fill: white !important;
        }
    }

    &.black {
        g {
            path {
                fill: black !important;
            }
        }
    }
    &.open {
        g {
            path {
                fill: white !important;
            }
        }
    }
}

.menu {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    background-color: $background;
    box-sizing: border-box;

    transition-duration: 500ms;
    &.close {
        transform: translateY(-100%);
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 48px;
    padding-top: 128px;
}
