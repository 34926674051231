@import "src/app/global/variables.scss";
.Logo {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
        font-weight: 500;
    }
    span {
        font-weight: 700;
    }

    @media screen and (max-width: 767px) {
        p {
            display: none;
        }
    }
}

.picture {
    width: 32px;
    height: 32px;
}
