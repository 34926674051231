@import "src/app/global/variables.scss";
.container {
    position: relative;
    h3 {
        font-size: 32px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.BusinessInfo {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 16px;
    border: none;
    background-color: transparent;
    border-bottom: 1px black solid;

    cursor: pointer;
    transition-duration: 500ms;

    &.open {
        svg {
            transform: rotateZ(180deg);
        }
    }

    svg {
        transition-duration: 500ms;
        transform: rotateZ(0deg);
    }
    
    font-weight: 600;
}

.arrow {
    transition-duration: 500ms;
}
.arrow.open {
    transform: rotateZ(180deg);
}

.menu {
    position: absolute;
    top: -336px;
    left: calc(-100% - 200px);

    border: 1px solid rgba(180, 180, 180, 0.3);
    background-color: white;

    border-radius: 20px;
    padding: 16px;

    max-height: 300px;
    overflow-y: auto;
    height: 300px;
    opacity: 1;

    transition-duration: 500ms;

    &.close {
        top: 0;
        opacity: 0;
        height: 0;
        pointer-events: none;
    }

    &::-webkit-scrollbar {
        width: 5px; /* ширина scrollbar */
        background-color: transparent;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px; /* цвет дорожки */
        opacity: 50%;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(
            180,
            180,
            180,
            0.3
        ); /* цвет плашки */
        border-radius: 20px; /* закругления плашки */
    }
    &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 12px;
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 20px;
    }

    @media screen and (max-width: 768px) {
        left: -71px;
    }
}

.tableBody {
    display: flex;
    flex-direction: column;
    gap: 20px;

    tr {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(210, 210, 210, 0.7);
        padding: 4px;

        &.large {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }

    th {
        min-width: 120px;
        text-align: left;
    }
}
