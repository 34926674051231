@import "src/app/global/variables.scss";
* {
    font-family: "Archivo", "Poppins", sans-serif,
        -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue";
    margin: 0;
}

html,
body {
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

img {
    object-fit: contain;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        "Courier New", monospace;
}

h1 {
    font-size: $font-xl;
    font-weight: 500;
}

h2 {
    font-size: $font-l;
}

h3 {
    font-size: $font-m;
}

h4 {
    font-size: $font-sm;
    font-weight: 400;
}

p {
    font-size: $font-s;
}

@media screen and (max-width: 1024px) {
    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: $font-m;
    }

    h4 {
        font-size: 16px;
        font-weight: 400;
    }

    p {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: $font-m;
    }

    h4 {
        font-size: 16px;
        font-weight: 400;
    }

    p {
        font-size: 16px;
    }
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandard.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandardLight.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandardMedium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandardBold.ttf");
    font-weight: 700;
}
