@import "src/app/global/variables.scss";
.Button {
    padding: 12px 32px;
    cursor: pointer;
    opacity: 1;
    mix-blend-mode: normal;
    transition-duration: 300ms;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
        pointer-events: none;
    }

    p {
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
    }

    &:hover {
        opacity: 0.6;
    }
}

.Button.withArrow {
    display: flex;
    // flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.Button.withArrow.outlined {
    svg {
        path {
            fill: $text;
        }
    }
}

.Button.withArrow.primary {
    svg {
        path {
            fill: black;
        }
    }
}


.Button.outlined {
    border-radius: 12px;
    border: 0;
    color: $text;
    padding: 16px 32px;
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.04),
        rgba(255, 255, 255, 0.2)
    );
    backdrop-filter: blur(3.5px);
    box-shadow: 1.5px 1px rgba($color: #fff, $alpha: 0.5),
        -1.5px -1px rgba($color: #fff, $alpha: 0.3);
    p {
        color: var(--White, var(--White, #fff));
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    &:hover {
        box-shadow: 1.5px -1px rgba($color: #fff, $alpha:
                        0.5),
            -1.5px 1px rgba($color: #fff, $alpha: 0.3);
    }
}

.Button.primary {
    background-color: $accent;
    border: 1px solid $accent;
    border-radius: 8px;

    p {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.Button.secondary {
    padding: 24px 62px;
    background-color: $primary;
    color: $light-text;
    border: 1px solid $primary;
    border-radius: 8px;
}

.Button.none {
    border: 0;
    background-color: transparent;
    color: $text;
}

.Button.black {
    border-radius: 8px;
    background-color: black;
    color: white;
}

.Button.white {
    background-color: white;
    padding: 16px 32px;
    border-radius: 12px;
    border: 0;
    p {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
