@import "src/app/global/variables.scss";
.TeamBlock {
    position: relative;
    z-index: 3;
    background-color: white;
    padding: 100px 120px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;

    h4 {
        color: #878787;
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }
    @media screen and (min-width: 1439px) {
        padding-left: calc((100vw - 1340px) / 2);
        padding-right: calc((100vw - 1340px) / 2);
    }
    @media screen and (max-width: 767px) {
        padding: 42px 24px;

        h4 {
            font-size: 24px;
        }
    }
}

.TeamContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 72px;
    margin-top: 64px;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
}

.teammateImg {
    width: 200px;
    height: 200px;
    border-radius: 50%;

    @media screen and (max-width: 767px) {
        width: 100px;
        height: 100px;
    }
}

.waveUp {
    position: absolute;
    top: -1300px;
    z-index: -1;
    left: 0px;
    width: calc(100vw + 300px);
    pointer-events: none;

    @media screen and (max-width: 767px) {
        width: 2200px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 2100px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 1900px;
        top: -1100px;
    }
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
        top: -1100px;
    }
    @media screen and (min-width: 1921px) {
        max-height: 1400px;
        top: -800px;
        width: 100vw;
        object-fit: fill;
    }
}
