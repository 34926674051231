@import "src/app/global/variables.scss";
.Feedback {
    background-color: white;
    padding: 140px 0px;

    h4 {
        color: #878787;
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;
        margin-left: 120px;
    }
    @media screen and (min-width: 1920px) {
        padding-left: calc((100vw - 1920px) / 2);
        padding-right: calc((100vw - 1920px) / 2);
    }
    @media screen and (max-width: 767px) {
        padding: 42px 0px;
        h4 {
            font-size: 24px;
            margin-left: 24px;
        }
    }
}

.container {
    display: flex;
    gap: 56px;
    margin-top: 60px;

    @media screen and (max-width: 767px) {
        gap: 12px;
        margin-top: 42px;
    }
}

.marquee {
    display: flex;
    gap: 56px;
    padding: 10px;

    @media screen and (max-width: 767px) {
        gap: 12px;
    }
}
