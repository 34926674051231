@import "src/app/global/variables.scss";
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 100vw;
    overflow: hidden;
    background-color: $background;
}
.Main {
    margin-top: 120px;
    // max-width: 1920px;
    width: 100%;
    position: relative;
}

$orbit-radius: 2000px;
$orbit-x: 800px;
$orbit-y: 200px;

$orbit-offset: 300px;

$circle-radius: 170px;

$orbit-radius-contact: 1500px;
$orbit-x-contact: 400px;
$orbit-y-contact: 200px;

$orbit-offset-contact: 300px;

$circle-radius-contact: 170px;

.orbitContainer {
    position: absolute;
    z-index: 2;
    top: $orbit-y;
    left: $orbit-x;
    width: $orbit-radius;
    height: $orbit-radius;

    display: flex;
    align-items: center;
    justify-items: center;
    transform: scaleX(1.2);
    opacity: 0.3;
    @media screen and (max-width: 768px) {
        top: 200px;
        left: -500px;
    }

    @media screen and (min-width: 2500px) and (max-width: 3999px) {
        left: $orbit-x*2;
    }

    @media screen and (min-width: 4000px) {
        left: $orbit-x*3;
    }
}

.orbit {
    position: absolute;

    width: $orbit-radius;
    height: $orbit-radius;

    border: 2px solid white;
    opacity: 0.7;
    border-radius: 9999px;
    &.second {
        width: calc($orbit-radius - $orbit-offset);
        height: calc($orbit-radius - $orbit-offset);
        left: calc(
            50% - ($orbit-radius - $orbit-offset) / 2
        );
    }
}

.animCircle {
    position: relative;
    width: $orbit-radius;
    height: $orbit-radius;

    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: orbit;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-name: orbit;
    -webkit-animation-duration: 20s;
}

.animCircleDot {
    top: calc(0px - $circle-radius / 2);
    left: calc(50% - $circle-radius / 2);
    position: absolute;
    width: $circle-radius;
    height: $circle-radius;

    border: 2px solid white;
    opacity: 0.7;
    border-radius: 9999px;

    &.second {
        top: calc(100% - $circle-radius / 2);
    }
}

@keyframes orbit {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
    }
}

.background {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 1024px) {
        height: 860px;
        object-fit: cover;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        height: 1060px;
        object-fit: cover;
    }
}