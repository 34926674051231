@import "src/app/global/variables.scss";
.container {
    position: relative;

    
}
.baseTextarea {
    background-color: transparent;
    font-family: "Berthold Standard";
    padding: 0px 5px;
    outline: none;
    border: 0px;
    font-size: 20px;
    resize: none;
    border-bottom: 4px solid gray;
    &:focus {
        border-bottom: 4px solid $primary;
    }

    &.invalid {
        border-bottom: 4px solid red;
    }
    color: black;

}

.placeholder {
    margin-bottom: 8px;
    font-size: 20px;
    font-family: "Berthold Standard";
    opacity: 0.6;
}

.warning {
    position: absolute;
    top: calc(100% + 48px);
    &.noText {
        top: calc(100% + 16px);
    }
    color: red;
    opacity: 1;
    transition-duration: 500ms;

    &.hide {
        opacity: 0;
    }
}