@import "src/app/global/variables.scss";
.licenseCheck {
    margin: 166px 335px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        margin: 166px 24px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin: 166px 120px;
    }

    @media screen and (min-width: 1921px) {
        padding-left: calc((100vw - 1921px) / 2);
        padding-right: calc((100vw - 1921px) / 2);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 138.889% */
    }

    a {
        color: var(--Color, #653685);
        font-family: "Berthold Standard";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
        user-select: none;
    }
}

.licenseStatus {
    margin-top: 32px;
}

.licenseInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 42px;
    @media screen and (max-width: 820px) {
        flex-direction: column;
        gap: 24px;
        .renewButton {
            width: 100%;
        }
    }
}

.licenseInfo {
    display: flex;
    flex-direction: column;

    gap: 20px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 300px;
        span {
            font-weight: 700;
        }
        p {
            color: var(--Black, #000);
            font-family: "Berthold Standard";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            opacity: 0.5;
        }
    }
}

.renewButton {
    padding: 32px 42px;
    max-height: 90px;
    p {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
    }
}

.licenseCheckForm {
    position: relative;
    z-index: 5;
    border-radius: 20px;
    border: 1px solid #fff;
    background: #f6f6f6;
    backdrop-filter: blur(6px);
    margin-top: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    padding: 12px 64px;
    border-radius: 20px;

    h3 {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 280px;
        min-width: 280px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 42px 24px;
        max-width: fit-content;
        place-self: center;
        .headContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        h3 {
            max-width: 9999px;
        }
    }

    // @media screen and (min-width: 768px) and (max-width: 1024px) {
    //     padding: 42px 84px;
    // }
}
.licenseImage {
    width: 112px;
}
.licenseField {
    border-bottom: 1px solid gray !important;

    &.focus {
        transition-duration: 500ms;
        border-bottom: 1px solid $primary !important;
    }
    input {
        padding: 32px;
        min-width: 310px;
    }

    @media screen and (max-width: 767px) {
        input {
            padding: 16px;
            min-width: 210px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        input {
            padding: 16px;
            min-width: 250px;
        }
    }
}

.checkButton {
    background-color: black !important;
    border: 0 !important;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
}
