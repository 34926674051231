@import "src/app/global/variables.scss";
.footer {
    display: flex;
    gap: 400px;
    justify-content: center;
    align-items: center;
    padding: 48px 80px;
    border-top: 1px solid gray;
    background-color: white;
    @media screen and (min-width: 1920px) {
        padding-left: calc((100vw - 1920px) / 2);
        padding-right: calc((100vw - 1920px) / 2);
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 24px;
        max-height: 400px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        gap: 200px;
        max-height: 400px;
    }
}

.footer_dark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px;
    border-top: 1px solid gray;
    background-color: white;
    margin-top: 120px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 24px;
    }
}

.MiniLogo {
    cursor: pointer;
    position: relative;
    left: -40px;
    min-width: 36px;
    max-width: 36px;
    @media screen and (max-width: 767px) {
        left: 0;
    }
}
