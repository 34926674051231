@import "src/app/global/variables.scss";
.container {
    margin-top: 170px;
    margin-bottom: 200px;
    margin-left: 335px;
    margin-right: 335px;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 56px;
    h1 {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 138.889% */
    }
    h2 {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 138.889% */
    }
    p {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
    }

    @media screen and (max-width: 1000px) and (min-width: 768px) {
        margin-left: 120px;
        margin-right: 120px;
    }
    @media screen and (min-width: 1920px) {
        padding-left: calc((100vw - 1920px) / 2);
        padding-right: calc((100vw - 1920px) / 2);
    }

    section {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.points {
    margin-bottom: 24px;
}
