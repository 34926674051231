@import "src/app/global/variables.scss";
.services {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 99;
    margin-top: 115px;
    background-color: white;
    padding: 114px 120px;
    margin-top: 245px;

    h4 {
        color: #878787;
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }
    @media screen and (min-width: 1439px) {
        padding-left: calc((100vw - 1340px) / 2);
        padding-right: calc((100vw - 1340px) / 2);
    }
    @media screen and (max-width: 820px) {
        padding: 114px 24px;

        h4 {
            font-size: 24px;
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    gap: 106px;
    margin-top: 72px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
    @media screen and (min-width: 768px) and (max-width: 820px) {
        gap: 56px;
    }
}

.directions {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 42px;

    position: relative;
    z-index: 2;

    padding-inline-start: 0px;
}

.directionsItem {
    border-bottom: 1px solid black;
    padding-bottom: 32px;
    width: fit-content;
    min-width: 650px;
    max-width: 650px;
    display: flex;
    align-items: center;
    gap: 24px;

    h2 {
        color: var(--Black, var(--Black, #000));
        font-family: "Berthold Standard";
        font-size: 56px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        white-space: pre-line;
    }

    @media screen and (min-width: 351px) and (max-width: 767px) {
        min-width: 320px;
        max-width: auto;
        h2 {
            font-size: 24px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 350px) {
        min-width: 290px;
        max-width: auto;
        h2 {
            font-size: 24px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: 380px;
        h2 {
            font-size: 32px;
        }
    }
}

.statisctics {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 450px;
    position: relative;
    z-index: 2;
    p {
        color: var(--Black, var(--Black, #000));
        font-family: "Berthold Standard";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 150% */
        letter-spacing: -0.24px;
    }

    @media screen and (max-width: 767px) {
        min-width: 380px;
        margin-top: 32px;
        p {
            font-size: 20px;
            max-width: 330px;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 820px) {
        p {
            font-size: 19px;
        }
    }
}

.AxgridLogo {
    @media screen and (max-width: 820px) {
        max-width: 240px;
    }
}
.moreAxgridButton {
    position: relative;
    margin-top: 84px;
    place-self: center;
    z-index: 10;
    color: white;
    background-color: black;
    padding: 16px 32px 16px 42px;
    cursor: pointer;
    opacity: 1;
    border: none;
    mix-blend-mode: normal;
    transition-duration: 300ms;
    border-radius: 12px;
    display: flex;
    // flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
        pointer-events: none;
    }

    p {
        color: var(--White, #fff);

        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &:hover {
        opacity: 0.6;
    }
}

.statItem {
    h2 {
        color: var(--Black, var(--Black, #000));
        font-family: "Berthold Standard";
        font-size: 72px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    span {
        color: var(--Black, var(--Black, #000));
        font-family: "Berthold Standard";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
        letter-spacing: -0.24px;
        opacity: 0.5;
    }

    @media screen and (min-width: 768px) and (max-width: 820px) {
        h2 {
            font-size: 56px;
        }
        span {
            font-size: 20px;
        }
    }
}

.subtract {
    top: 368px;
    left: calc(50% + 400px);
    position: absolute;
    z-index: 1;
}

.waveUp {
    position: absolute;
    top: -490px;
    width: calc(100vw);
    left: 0;
    z-index: -1;
    pointer-events: none;
    // display: none;
    @media screen and (max-width: 767px) {
        width: 1600px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 1800px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 1800px;
    }

    @media screen and (min-width: 2000px) {
        max-height: 600px;
        width: 100vw;
        object-fit: fill;
    }
}

.waveDown {
    position: absolute;
    width: calc(100vw + 400px);
    top: -380px;
    z-index: 1;
    left: 0px;
    pointer-events: none;

    @media screen and (max-width: 767px) {
        width: 1400px;
        top: 700px;
    }

    @media screen and (min-width: 768px) and (max-width: 820px) {
        width: 2050px;
    }
    @media screen and (min-width: 821px) and (max-width: 1024px) {
        width: 2150px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 1700px;
        top: 50px;
    }
    @media screen and (min-width: 1441px) and (max-width: 1680px) {
        width: 1800px;
        top: -100px;
    }

    @media screen and (min-width: 1921px) {
        max-height: 1400px;
        top: 200px;
        width: 100vw;
        object-fit: fill;
    }
}
