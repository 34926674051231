@import "src/app/global/variables.scss";
.container {
    display: flex;
    align-items: center;
    gap: 64px;
    width: fit-content;
    @media screen and (max-width: 767px) {
        align-items: start;
        gap: 24px;
    }
}

.typography {
    display: flex;
    flex-direction: column;
}

.name {
    color: var(--Black, #000);
    font-family: "Berthold Standard";
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    white-space: pre-line;
    @media screen and (max-width: 767px) {
        font-size: 32px;
        font-weight: 300;
    }
}

.role {
    color: var(--Neutral-50, #898989);
    font-family: "Berthold Standard";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: 14px;
        font-weight: 400;
    }
}

.img {
    width: 200px;
    height: 200px;
    border-radius: 9999px;
    border: 0;
    flex-shrink: 0;
    background: lightgray 50% / cover no-repeat;

    @media screen and (max-width: 767px) {
        width: 100px;
        height: 100px;
    }
}
