@import '../themes/light.scss';

//typography sizes
$font-xxs: 10px; 
$font-xs: 12px;
$font-s: 14px;
$font-sm: 16px;
$font-m: 18px;
$font-l: 40px;
$font-xl: 56px; 
$font-xxl: 67px; 
//borders
$border-xs: 5px;
$border-s: 8.276px;


//mobile typography sizes
$font-xxs: 10px; 
$font-xs: 12px;
$font-s: 14px;
$font-sm: 16px;
$font-m: 18px;
$font-l: 40px;
$font-xl: 56px; 
$font-xxl: 67px; 