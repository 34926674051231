@import "src/app/global/variables.scss";
.Navigation {
    list-style: none;
    display: flex;
    gap: 16px;
    padding-inline-start: 0px;
    p {
        color:  #fff;
        font-family: sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    @media screen and (max-width: 950px) and (orientation: landscape) {
        flex-direction: row !important;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        p {
            font-size: 18px;
            text-align: center;
            color: white !important;
        }
    }
    
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
        gap: 32px;
        p {
            font-size: 24px;
            text-align: center;
            color: white !important;
        }
    }
}

.item {
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    width: fit-content;
    font-size: $font-s;
    transition-duration: 300ms;

    &:hover {
        transform: scale(1.05);
    }
}

.item.current {
    background-color: $element-background;
}
