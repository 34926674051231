@import "src/app/global/variables.scss";
.Configuration {
    position: relative;
    background-color: white;
    border-radius: 12px;
    padding: 36px 20px;
    margin: 16px 16px;

    img {
        max-width: 400px;
    }

    display: flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: 300px;
        img {
            max-width: 300px;
        }
    }
    @media screen and (max-width: 820px) and (min-width: 768px) {
        img {
            max-width: 250px;
        }
    }
}

.specification {
    max-height: 330px;
    overflow-y: scroll;
    scrollbar-width: thin; /* "auto" или "thin"  */
    scrollbar-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
    color: var(--Black, var(--Black, #000));
    font-family: "Berthold Standard";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 262.5% */
    table {
        border-spacing: 10px;
    }
    table tr td:first-child {
        font-weight: bold;
    }

    table tr td:last-child {
        max-width: 240px;
    }

    @media screen and (max-width: 767px) {
        table tr td:last-child {
            max-width: 140px;
        }
    }
}

.tabs {
    display: flex;
    gap: 32px;
    h3 {
        color: #878787;
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .selected {
        color: $primary;
        padding-bottom: 12px;
        border-bottom: 2px solid $primary;
    }

    @media screen and (max-width: 767px) {
        h3 {
            font-size: 14px;
        }
    }
}

.xMark {
    position: absolute;
    right: 12px;
    top: 16px;
    cursor: pointer;
    path {
        stroke: gray;
    }
}

.docs {
    margin-top: 32px;

    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
}

.docCard {
    padding: 16px 32px;
    border-radius: 12px;
    background: var(--Brand-Purple-100, #f1e4ff);
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    cursor: pointer;
}
