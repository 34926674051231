$black-blue: #20184e;
$gray-color: #666;

$primary: #653685;
$secondary: #3772ff;
$border-color: #353945;
$accent: #cafe14;
$element-background: #f4f5f6;
$second-background: #f7f7f8;
$light-gray: #ececee;
$dark-gray: #777e90;
$text: #141416;
$light-text: #ffffff;
$gray-text: #b1b5c3;
$error: #ff0000;
$background: #653685;
$plan-border: #e4e4e7;
$input-border: #e6e8ec;

$pallete-yellow: #ffd166;
$pallete-violet: #9757d7;
$pallete-red: #ef466f;
