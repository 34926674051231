@import "src/app/global/variables.scss";
.ProductsBlock {
    padding: 136px 72px;
    padding-top: 400px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    background-color: $background;
    h4 {
        color: var(--White, #fff);
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;
        margin-left: 42px;
        place-self: start;
    }
    @media screen and (min-width: 1439px) {
        padding-left: calc((100vw - 1340px) / 2);
        padding-right: calc((100vw - 1340px) / 2);
    }
    @media screen and (max-width: 767px) {
        padding: 42px 24px;
        padding-top: 200px;
        h4 {
            font-size: 24px;
            margin-left: 0px;
            place-self: center;
            margin-bottom: 16px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding-top: 200px;
    }
}

.container {
    display: flex;

    gap: 42px;
    margin-top: 39px;
    padding: 12px 120px 12px 120px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 1025px) and (max-width: 1280px) {
        max-width: 1100px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: auto;
        max-width: 1400px;
    }
    overflow: hidden;

    cursor: grab;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
        overflow: scroll;
        scrollbar-width: none;
    }
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    position: relative;
    z-index: 4;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.card {
    position: relative;
    background-color: rgba($color: #000000, $alpha: 0.1);
    backdrop-filter: blur(3.5px);
    box-shadow: 1.5px 1px rgba($color: #fff, $alpha: 0.5),
        -1.5px -1px rgba($color: #fff, $alpha: 0.3);
    border-radius: 20px;
    padding: 24px 32px 54px 32px;
    width: fit-content;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    user-select: none;
    z-index: 1;
    .image {
        max-width: 410px;
        border-radius: 20px;
        user-select: none;
    }
    h1 {
        color: var(--White, var(--White, #fff));
        font-family: "Berthold Standard";
        font-size: 56px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .text {
        max-width: 360px;
        p {
            color: var(--White, #fff);
            font-family: "Berthold Standard";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 160% */
            margin-top: 12px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        padding: 24px 24px 54px 24px;
        h1 {
            font-size: 36px;
        }

        p {
            font-size: 16px;
        }

        .image {
            max-width: 280px;
        }
    }

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        h1 {
            font-size: 32px;
        }
        .text {
            max-width: 360px;
            p {
                font-size: 16px;
            }
        }

        .image {
            max-width: 320px;
        }
    }
}

.circles {
    max-width: 100%;
    position: absolute;
    bottom: 0px;
    pointer-events: none;
}

.visitButton {
    position: relative;
    z-index: 4;
    width: fit-content;
    // margin-top: 72px;
    p {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    place-self: center;

    &:hover {
        .comingSoon {
            animation-name: rotation;
            animation-duration: 0.5s;
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
        }
    }

    @media screen and (max-width: 767px) {
        p {
            p {
                font-size: 12px !important;
            }
        }
    }
}
.downloadButton {
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}
.configButton {
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}
.SomeIcon {
    position: absolute;
    left: 0px;
    bottom: 255px;
}

.easyLaunch {
    max-height: 65px;
    margin-top: 32px;
}

.comingSoon {
    position: absolute;
    top: calc(100% - 10px);
    opacity: 1;
    transform-origin: top;
    z-index: 5;
}

.bgLine1 {
    position: absolute;
    transform: rotateY(180deg);
    left: 0;
    pointer-events: none;
}

.bgLine2 {
    position: absolute;
    // transform: rotateY(180deg);
    right: 0;
    top: 1000px;
    pointer-events: none;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(15deg);
    }
    70% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.licenseCheck {
    position: relative;
    z-index: 5;
    background-color: white;
    margin-top: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    padding: 12px 64px;
    border-radius: 20px;

    h3 {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        white-space: nowrap;
    }

    a {
        color: var(--Color, #653685);
        font-family: "Berthold Standard";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 42px 24px;
        .headContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 820px) {
        padding: 24px 34px;
        gap: 12px;
        .licenseImage {
            width: 64px;
        }

        h3 {
            font-size: 24px;
        }
    }
    @media screen and (min-width: 821px) and (max-width: 1024px) {
        padding: 24px 34px;
    }
}
.licenseImage {
    width: 112px;
}
.licenseField {
    border-bottom: 1px solid gray !important;

    &.focus {
        transition-duration: 500ms;
        border-bottom: 1px solid $primary !important;
    }
    input {
        padding: 32px;
        min-width: 310px;
    }

    @media screen and (max-width: 767px) {
        input {
            padding: 16px;
            min-width: 210px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        input {
            padding: 16px;
            min-width: 250px;
        }
    }
}

.checkButton {
    background-color: black !important;
    border: 0 !important;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
