@import "src/app/global/variables.scss";
.HomeBlock {
    position: relative;
    z-index: 2;
    @media screen and (min-width: 1439px) {
        padding-left: calc((100vw - 1340px) / 2);
        padding-right: calc((100vw - 1340px) / 2);
    }
}

.text {
    margin-top: 130px;
    margin-bottom: 210px;

    h1 {
        color: var(--White, #fff);
        font-family: "Berthold Standard";
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    h1:first-child {
        color: $accent;
    }

    p {
        color: var(--White, #fff);
        font-family: "Berthold Standard";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
        max-width: 413px;
        margin-top: 32px;
    }
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 32px;

        margin-top: 42px;
        margin-left: 24px;

        h1 {
            font-family: "Berthold Standard";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        p {
            font-family: "Berthold Standard";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
            letter-spacing: -0.18px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 820px) {
        margin-top: 42px;
        margin-left: 24px;
    }

    @media screen and (min-width: 768px) and (max-width: 1440px) {
        margin-left: 120px;
    }
}

.background {
    width: 100%;
    height: auto;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // mix-blend-mode: multiply;
    opacity: 0.5;

    @media screen and (max-width: 1024px) {
        height: 760px;
        object-fit: cover;
    }
}

.iconStar {
    position: absolute;
    top: 0;
    left: calc(50% - 100px);
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
        left: calc(50% + 100px);
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        left: calc(50% + 200px);
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.bgLine {
    position: absolute;
    top: 300px;
    opacity: 0.5;
    transform: rotateY(180deg);
    z-index: 1;
    width: 90px;
}
