@import "src/app/global/variables.scss";
.FeedbackCard {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: var(--White, #fff);
    width: min-content;
    img {
        width: 467px;
        height: auto;
        object-fit: cover;
    }

    h3 {
        color: var(--Black, var(--Black, #000));
        font-family: "Berthold Standard";
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 100% */
    }

    p {
        color: var(--Black, var(--Black, #000));
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 160% */
        letter-spacing: -0.2px;
    }
    transition-duration: 300ms;
    &:hover {
        transform: scale(1.03);
    }

    @media screen and (max-width: 767px) {
        gap: 30px;
        img {
            width: 300px;
        }
        h3 {
            font-size: 27px;
        }

        p {
            font-size: 15px;
        }
    }
}

.breakLine {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
