@import "src/app/global/variables.scss";
.LicenseStatus {
    display: flex;
    padding: 16px 42px 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    p {
        color: var(--Black, #000);
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    &.valid {
        background: #a3ff53;
    }
    &.soon {
        background: #ffe853;
    }

    &.expire {
        background: #ff5353;
    }
}
