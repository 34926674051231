@import "src/app/global/variables.scss";
.Contacts {
    position: relative;
    padding: 100px 120px;
    padding-bottom: 150px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    h3 {
        color: var(--Black, var(--Black, #000));
        text-align: center;
        font-family: "Berthold Standard";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 56px; /* 133.333% */
        letter-spacing: -0.42px;
    }
    @media screen and (min-width: 1920px) {
        padding-left: calc((100vw - 1920px) / 2);
        padding-right: calc((100vw - 1920px) / 2);
    }
    @media screen and (max-width: 767px) {
        h3 {
            font-size: 28px;
        }

        padding: 42px 24px;
        padding-bottom: 100px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    margin-top: 46px;
}

.textArea {
    width: 100%;
    max-width: calc(372 * 3px + 80px);
    // min-width: 530px;
    height: 150px;
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        min-width: min-content;
    }
}

.textFields {
    display: flex;
    gap: 40px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.privacyPolicy {
    margin-top: 48px;

    display: flex;
    align-items: center;
    gap: 4px;

    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
    }
}

.privacyCheckBox {
    position: absolute;
    z-index: -10;
    opacity: 0;
}

.privacyCheckBox + .checkBoxLabel {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.privacyCheckBox + .checkBoxLabel::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.privacyCheckBox:checked + .checkBoxLabel::before {
    border-color: $primary;
    background-color: $primary;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.privacyLink {
    text-decoration: underline;
    cursor: pointer;
}

.successMessage {
    position: absolute;
    top: calc(100% - 150px);
    display: flex;
    max-width: fit-content;
    cursor: pointer;
    padding: 12px 0px;
    border-bottom: 3px solid $primary;
    margin-top: 42px;
    opacity: 1;
    color: black;
    text-align: center;
    font-family: "Berthold Standard";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        top: calc(100% - 120px);
    }
    transition-duration: 500ms;
    &.hide {
        opacity: 0;
    }
}
